import { setPageTitle } from '@/utils/common/system'
import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'

import { VuexStateKey, VuexModuleKey, VuexActionKey } from '@/store/vuexEnum'
import store from '@/store'
import NProgress from 'nprogress' // progress bar
import { routerBeforeEach, settingRoutesG, recursionGetConstRoute } from '@kzfe/learnable-lib/package/vue3/framework/router/index'
import { getRouteComponent } from '@kzfe/learnable-lib/package/vue3/framework/router/getRouteComponent'
import { initFramework } from '@kzfe/learnable-lib/package/vue3/framework/config/config'
import G, { DOMAIN_MAP } from '@/config/config'
import { isLogin } from '@kzfe/learnable-lib/package/vue3/framework/utils/common/user'
import { EnumItem } from '@/api/enum'
import Main from '@kzfe/learnable-lib/package/vue3/framework/components/Main/Main.vue'
import { trackRouter } from 'vue-gtag-next'
import { setKeepAliveAndTransition } from 'vue-page-like-app'
const routes: Array<RouteRecordRaw> = [
	{
		path: '/',
		redirect: '/examinationPaper/list',
	},
	{
		path: '/examinationPaper',
		redirect: '/examinationPaper/list',
		component: Main,
		name: 'examinationPaper',
		meta: { title: '试卷配置', menuShow: true, homePage: true, icon: '&#xe61e;', deepth: 1 },
		children: [
			{
				path: '/examinationPaper/list',
				name: 'examinationPaperList',
				component: () => getRouteComponent('examinationPaperList', import('../views/examinationPaper/Index.vue')),
				meta: { title: '试卷列表', icon: '&#xe64a;', menuShow: true, deepth: 2 },
			},
			{
				path: '/examinationPaper/questionList',
				name: '/examinationPaperQuestionList',
				component: () => getRouteComponent('examinationPaperQuestionList', import('../views/questionBank/question/questionList/Index.vue')),
				meta: { title: '题目列表', icon: '&#xe609;', deepth: 2 },
			},
			{
				path: '/examinationPaper/questionDetail',
				name: '/examinationPaperQuestionDetail',
				component: () => getRouteComponent('examinationPaperQuestionDetail', import('../views/questionBank/question/questionDetail/Index.vue')),
				meta: { title: '批改配置', configType: 'exam', icon: '&#xe78c;', deepth: 2, requsetSource: 'exam' },
			},
			{
				path: '/examinationPaper/questionSingleDetail',
				name: '/examinationPaperQuestionSingleDetail',
				component: () => getRouteComponent('examinationPaperQuestionSingleDetail', import('../views/questionBank/question/questionDetail/Index.vue')),
				meta: { title: '单题批改', configType: 'single_grader', icon: '&#xe78c;', deepth: 2, requsetSource: 'single' },
			},
		],
	},
	{
		path: '/concludingPaper',
		redirect: '/concludingPaper/list',
		component: Main,
		name: 'concludingPaper',
		meta: { title: '押题试卷', menuShow: true, homePage: true, icon: '&#xe61e;', deepth: 1 },
		children: [
			{
				path: '/concludingPaper/list',
				name: 'concludingPaperList',
				component: () => getRouteComponent('examinationPaperList', import('../views/concludingPaper/Index.vue')),
				meta: { title: '押题试卷列表', icon: '&#xe64a;', menuShow: true, deepth: 2 },
			},
			{
				path: '/concludingPaper/questionList',
				name: '/concludingPaperQuestionList',
				component: () => getRouteComponent('concludingPaperQuestionList', import('../views/questionBank/question/questionList/concluding.vue')),
				meta: { title: '试卷题目列表', icon: '&#xe609;', deepth: 2 },
			},
			{
				path: '/concludingPaper/questionDetail',
				name: '/concludingPaperQuestionDetail',
				component: () => getRouteComponent('concludingPaperQuestionDetail', import('../views/questionBank/question/questionDetail/concluding.vue')),
				meta: { title: '批改配置', configType: 'exam', icon: '&#xe78c;', deepth: 2, requsetSource: 'exam' },
			},
		],
	},
	{
		path: '/exerciseBook',
		redirect: '/exerciseBook/list',
		component: Main,
		name: 'exerciseBook',
		meta: { title: '练习册配置', menuShow: true, homePage: true, icon: '&#xe632;', deepth: 1 },
		children: [
			{
				path: '/exerciseBook/list',
				name: 'exerciseBookList',
				component: () => getRouteComponent('exerciseBookList', import('../views/exerciseBook/List.vue')),
				meta: { title: '练习册列表', icon: '&#xe64a;', menuShow: true, deepth: 2 },
			},
			{
				path: '/exerciseBook/chapterList',
				name: 'exerciseBookChapterList',
				component: () => getRouteComponent('exerciseBookChapterList', import('../views/exerciseBook/chapterList.vue')),
				meta: { title: '章节列表', icon: '&#xe64a;', menuShow: false, deepth: 2 },
			},
			{
				path: '/exerciseBook/questionList',
				name: 'exerciseBookQuestionList',
				component: () => getRouteComponent('exerciseBookQuestionList', import('../views/exerciseBook/questionList.vue')),
				meta: { title: '题目列表', icon: '&#xe64a;', menuShow: false, deepth: 2 },
			},
			{
				path: '/exerciseBook/questionDetail',
				name: 'exerciseBookQuestionDetail',
				component: () => getRouteComponent('exerciseBookQuestionDetail', import('../views/questionBank/question/questionDetail/Index.vue')),
				meta: { title: '批改配置', configType: 'exercise', icon: '&#xe78c;', deepth: 2, requsetSource: 'exercise' },
			},
		],
	},
	{
		path: '/Test',
		component: () => getRouteComponent('Test', import('../views/Test.vue')),
	},
	{
		path: '/login',
		name: 'login',
		component: () => getRouteComponent('login', import('@kzfe/learnable-lib/package/vue3/framework/views/Login.vue')), // 公共库中的登录页
		meta: {
			isConstRoute: true,
			noLogin: true,
			deepth: 0,
		},
	},
]

export const constRoutes = recursionGetConstRoute(routes)
const router: any = createRouter({
	history: createWebHistory(import.meta.env.BASE_URL),
	routes: constRoutes,
})
trackRouter(router)

export function settingRoutes() {
	settingRoutesG(router, routes)
}

initFramework(import.meta.env, G, store, router, DOMAIN_MAP, settingRoutes)

export default router

NProgress.configure({ showSpinner: false })

router.beforeEach((to, from, next) => {
	NProgress.start()
	routerBeforeEach(to, from, next)
})

router.beforeEach((to, from, next) => {
	setKeepAliveAndTransition(to, from)
	next()
})

router.afterEach(to => {
	NProgress.done()
	setPageTitle(to.meta.title)
	if (isLogin()) {
		const module = store.state[VuexModuleKey.enumModule] as {
			[x: string]: unknown
		}
		if (module) {
			const serverEnum = module[VuexStateKey.serverAllEnum] as {
				[key: string]: EnumItem
			}
			const keys = Object.keys(serverEnum)
			if (!keys.length) {
				store.dispatch(`${VuexModuleKey.enumModule}/${VuexActionKey.queryAllEnum}`)
			}
		}
	}
})
/** 兼容自定义跳转 */
router.fePush = function (data: any) {
	/** 开发环境，带端口，发布后一般不会带端口 */
	if (location.port) {
		router.push(data)
	} else {
		let { path, query } = data
		if (query) {
			let q: any = []
			for (const i in query) {
				q.push(`${i}=${query[i] || ''}`)
			}
			if (q.length) {
				q = `?${q.join('&')}`
				path += q
			}
		}
		window.open(path, '_blank')
	}
}
