const G = {
	isMock: false,
	ENV: import.meta.env.MODE,
	platform: import.meta.env.VITE_PLATFORM,
	defaultTitle: '批改配置平台',
	isKeepAlive: false,
}

export const DOMAIN_MAP = {
	domainName: 'VITE_BASE_URL',
	pdfDebug: import.meta.env.VITE_PDF_DEBUG,
	pdfApi: import.meta.env.VITE_PDF_SERVICE,
	pdfTemplate: import.meta.env.VITE_PDF_TEMPLATE,
	user: import.meta.env.VITE_USER_URL,
	ocr: import.meta.env.VITE_OCR_URL,
	questionBmp: import.meta.env.VITE_BASE_URL_QUESTION_BMP,
	tem: import.meta.env.VITE_TEM_URL,
	operation: import.meta.env.VITE_OPERATION_URL,
	graderConfig: import.meta.env.VITE_GRADER_CONFIG,
}

export default G
