import { createApp } from 'vue'
import ElementPlus from 'element-plus'
import { MotionPlugin } from '@vueuse/motion'

import App from '@kzfe/learnable-lib/package/vue3/framework/App.vue'
import router from './router'
import store from './store'

import '@/styles/index.scss'
import 'img-mark/dist/style.css'
import '@kzfe/learnable-lib/dist/style.css'
import 'element-plus/theme-chalk/index.css'
import 'dayjs/locale/zh-cn'
import locale from 'element-plus/lib/locale/lang/zh-cn'
import highlight from '@/utils/common/highlight' // 高亮指令v-highlight
import disableOption from '@/utils/common/disableOption' // select下拉框禁用选项
import { registDirective } from '@kzfe/learnable-lib/package/vue3/framework/directive'
import VueGtag from 'vue-gtag-next'

const app = createApp(App)
registDirective(app)
app.config.globalProperties.$t = undefined

import { setVueCompGlobalConfig } from '@kzfe/learnable-lib'

app.use(ElementPlus, {
	locale,
})
app.use(VueGtag)
app.use(highlight).use(disableOption)

app.use(MotionPlugin, {
	directives: {
		'main-show': {
			initial: {
				opacity: 0,
				y: 100,
			},
			enter: {
				opacity: 1,
				y: 0,
				transition: {
					type: 'spring',
					stiffness: 250,
					damping: 25,
					mass: 0.5,
				},
			},
			leave: {
				opacity: 0,
				y: -100,
			},
		},
	},
})
Number.prototype._toFixed = Number.prototype.toFixed
Number.prototype.toFixed = function (n = 2) {
	const temp = (this + '').split('.')
	if (!temp[1] || temp[1].length <= n) {
		return this._toFixed(n)
	} else {
		const nlast = temp[1].substring(n, n + 1)
		temp[1] = temp[1].substring(0, n) + (nlast >= 5 ? '9' : '1')
		return Number([temp[0], temp[1]].join('.'))._toFixed(n)
	}
}
app.use(store).use(router).mount('#app')
registDirective(app)

setVueCompGlobalConfig({
	blankLight: true,
})
/** 用于解决将空字符串和null也判定为数字的问题 */
const _isNaN = isNaN
window.isNaN = function (val: any) {
	if (val === '' || val === null) {
		return true
	}
	return _isNaN(val)
}
export default app
