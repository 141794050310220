/**
 * 枚举相关api
 */
import http from '@kzfe/learnable-lib/package/vue3/framework/utils/http'
import { cloneDeep } from 'lodash'
import G from '@/config/config'
import { EnvName } from '@/config/NodeEnv'
import store from '@/store'
import { VuexModuleKey, VuexStateKey } from '@/store/vuexEnum'
export interface EnumItem {
	index: number
	desc: string
	parentIndexList: number[] | null
	disabled?: boolean
}

// 所有枚举
export interface AllTypeEnumList {
	subjectEnum: EnumItem[]
	subjectTypeEnum: EnumItem[]
	teachingVersionEnum: EnumItem[]
	termEnum: EnumItem[]
	gradeEnum: EnumItem[]
	graderEnum: EnumItem[]
	lockStatusEnum: EnumItem[]
	questionTypeEnum: EnumItem[]
	reviewQuestionTypeEnum: EnumItem[]
	templateTypeEnum: EnumItem[]
	tagTypeEnum: EnumItem[]
	aiStatusEnum: EnumItem[]
	simQuestionStatusEnum: EnumItem[]
	difficultyEnum: EnumItem[]
	paperStatusEnum: EnumItem[] // 试卷状态
	paperYearEnum: EnumItem[] // 试卷年份
	knowledgePointStatusEnum: EnumItem[] // 知识点状态
	machineSupportEnum: EnumItem[] // 机器支持
	// 标注外包相关
	bookImportTaskStatusEnum: EnumItem[] // 主任务状态
	bookImportSubTaskStatusEnum: EnumItem[] // 子任务状态
	bookImportChapterTaskStatusEnum: EnumItem[] // 章节任务状态
	bookImportSubTaskTypeEnum: EnumItem[] // 子任务类型
	// MAIN_TASK(0, "主任务"),
	// SUB_QUESTION_CONTENT_MARK(1, "题目内容标注"),
	// SUB_POSITION_MARK(2, "坐标位置标注"),
	// SUB_TO_HEAVY(3, "去重"),
	// SUB_QUESTION_INFO_CONFIG(4, "题目信息配置"),
}

export interface PageEnumList {
	ifContainCutEnumList: EnumItem[]
}

export type EnumKey = keyof AllTypeEnumList
// 获取学校相关类型枚举
export function apiQueryAllEnum() {
	return http<AllTypeEnumList>('GET', '/enums/queryEnumList', {}, { timeout: 300000 })
}

// 学科枚举
export interface SubjectEnumList {
	subjectGroupList: SubjectEnumItem[]
	subjectList: SubjectEnumItem[]
}
export interface SubjectEnumItem {
	index?: number
	type: number
	name: string
	detailName?: string
}

export interface EnumColorItem {
	value: number
	label: string
	color: string
}

const DEFAULT_SERVER_ENUM: EnumItem = {
	index: -1,
	desc: '',
	parentIndexList: null,
}

// 根据index获取对应的枚举的属性(desc、color, parentIndex)
export function getServerEnumObjByCode(listName: EnumKey, index: number, prop: keyof EnumItem = 'desc'): string {
	const resultDefaultEnum = cloneDeep(DEFAULT_SERVER_ENUM) as EnumItem
	const envIsPro = G.ENV === EnvName.PRODUCTION
	const enumModule = store.state[VuexModuleKey.enumModule] as unknown as {
		[key: string]: unknown
	}
	const enums = enumModule[VuexStateKey.serverAllEnum] as unknown as AllTypeEnumList
	const list = enums[listName]
	if (!list) {
		resultDefaultEnum.desc = `getServerEnumObjByCode: can't find list array.`
		return envIsPro ? (DEFAULT_SERVER_ENUM[prop] as string) : '-'
	}
	const result = list.find(item => item.index == index)
	if (result) {
		resultDefaultEnum.index = index
		return result[prop] as string
	} else {
		resultDefaultEnum.desc = `code 【${index}】 can't find in ${JSON.stringify(list)}`
		return '-'
	}
}

export const OperationInfoType = [
	{
		index: 1,
		desc: '标注人',
	},
	{
		index: 2,
		desc: '质检人',
	},
	{
		index: 3,
		desc: '验收人',
	},
]
